import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL ?? "";
const API_VERSION = process.env.REACT_APP_API_VERSION ?? "v1";
const API_KEY = process.env.REACT_APP_API_KEY ?? "";

export const auditApi = createApi({
  reducerPath: "auditApi",
  tagTypes: [
    "Dictionaries",
    "Dictionary",
    "OperationOutcomes",
    "Users",
    "Mismatches",
  ],
  baseQuery: fetchBaseQuery({
    baseUrl: `${API_BASE_URL}/${API_VERSION}`,
    timeout: 15000,
    prepareHeaders: (headers) => {
      headers.set("x-apikey", API_KEY);

      const accessToken = localStorage.getItem("accessToken");
      if (accessToken) {
        headers.set("x-auth-token", `Bearer ${accessToken}`);
      }

      return headers;
    },
  }),
  endpoints: () => ({}),
});
