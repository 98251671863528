import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { fetchData } from "lib/api";

interface RegisterData {
  name: string;
  email: string;
  password: string;
}

interface LoginData {
  email: string;
  password: string;
}

interface AuthResponse {
  accessToken: string;
}

export const registerUser = createAsyncThunk(
  "auth/signup",
  async ({ name, email, password }: RegisterData, { rejectWithValue }) => {
    try {
      const registerResponse = await fetchData<AuthResponse>("/auth/signin", {
        method: "POST",
        data: { name, email, password },
      });

      localStorage.setItem("accessToken", registerResponse.accessToken);
      return registerResponse.accessToken;
    } catch (err: unknown) {
      if (err instanceof AxiosError) {
        return rejectWithValue({
          errors: {
            _form: {
              message: err.response?.statusText ?? err.message,
              statusCode: err.response?.status,
            },
          },
        });
      }
      return rejectWithValue({
        errors: {
          _form: ["Oops! Something went wrong"],
        },
      });
    }
  },
);

export const loginUser = createAsyncThunk(
  "auth/signin",
  async ({ email, password }: LoginData, { rejectWithValue }) => {
    try {
      const loginResponse = await fetchData<AuthResponse>("/auth/signin", {
        method: "POST",
        data: { email, password },
      });

      localStorage.setItem("accessToken", loginResponse.accessToken);
      return loginResponse.accessToken;
    } catch (err: unknown) {
      console.log(err);
      if (err instanceof AxiosError) {
        return rejectWithValue({
          errors: {
            _form: {
              message: err.response?.statusText ?? err.message,
              statusCode: err.response?.status,
            },
          },
        });
      }
      return rejectWithValue({
        errors: {
          _form: ["Oops! Something went wrong"],
        },
      });
    }
  },
);
