import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "hooks";
import {
  authSelector,
  checkTokenExpiration,
  removeToken,
} from "modules/user/infrastructure/repository/auth/authSlice";

const useCheckTokenExpiration = () => {
  const { accessToken } = useAppSelector(authSelector);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    dispatch(checkTokenExpiration());
    if (!accessToken) {
      dispatch(removeToken());
      navigate("/login");
    }
  }, [accessToken, dispatch, navigate, location]);
};

export default useCheckTokenExpiration;
