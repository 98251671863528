import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  MismatchFilters,
  MismatchResolutionOperation,
  MismatchStatus,
} from "modules/mismatches/domain/types";
import { baseFiltersSlice } from "modules/shared/infrastructure/api/baseFiltersSlice";
import { RootState } from "modules/shared/infrastructure/redux/auditStore";

interface MismatchesState extends MismatchFilters {
  selectedMismatchId: number | null;
  resolution: MismatchResolutionOperation | null;
}

const initialState: MismatchesState = {
  filters: {
    ...baseFiltersSlice.getInitialState().filters,
    status: null,
    resolution: null,
  },
  openModal: baseFiltersSlice.getInitialState().openModal,
  selectedMismatchId: null,
  resolution: null,
};

const mismatchesSlice = createSlice({
  name: "mismatches",
  initialState,
  reducers: {
    ...baseFiltersSlice.caseReducers,
    setResolution: (
      state,
      action: PayloadAction<MismatchResolutionOperation | null>,
    ) => {
      state.resolution = action.payload;
    },
    setSelectedMismatchId: (state, action: PayloadAction<number | null>) => {
      state.selectedMismatchId = action.payload;
    },
    setStatus: (state, action: PayloadAction<MismatchStatus | null>) => {
      state.filters.status = action.payload;
    },
    setFilterResolution: (
      state,
      action: PayloadAction<MismatchResolutionOperation | null>,
    ) => {
      state.filters.resolution = action.payload;
    },
    resetFilter: () => {
      return initialState;
    },
  },
});

export const {
  setPage,
  setTake,
  setOrder,
  setOrderBy,
  setSearchTerm,
  setResolution,
  setSelectedMismatchId,
  setOpenModal,
  setStartDate,
  setEndDate,
  setStatus,
  setFilterResolution,
} = mismatchesSlice.actions;

export const mismatchesSelector = (state: RootState) => state.mismatches;

export default mismatchesSlice.reducer;
