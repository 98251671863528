import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { OperationOutcomeViewerOption } from "modules/operationOutcomes/application/types";
import {
  OperationOutcomeFilters,
  OperationOutcomeIssueSeverity,
} from "modules/operationOutcomes/domain/types";
import { Source } from "modules/shared/application/types";
import { baseFiltersSlice } from "modules/shared/infrastructure/api/baseFiltersSlice";
import { RootState } from "modules/shared/infrastructure/redux/auditStore";

export interface OperationOutcomesSlice extends OperationOutcomeFilters {
  viewerOption: OperationOutcomeViewerOption;
  selectedOperationId?: string;
}

const initialState: OperationOutcomesSlice = {
  filters: {
    ...baseFiltersSlice.getInitialState().filters,
    source: "",
    severity: "",
  },
  openModal: baseFiltersSlice.getInitialState().openModal,
  viewerOption: "stepper",
  selectedOperationId: undefined,
};

export const operationOutcomesSlice = createSlice({
  name: "operationOutcomesFilter",
  initialState,
  reducers: {
    ...baseFiltersSlice.caseReducers,
    setSource: (state, action: PayloadAction<Source>) => {
      state.filters.source = action.payload;
    },
    setSeverity: (
      state,
      action: PayloadAction<OperationOutcomeIssueSeverity | "">,
    ) => {
      state.filters.severity = action.payload;
    },
    setViewerOption: (
      state,
      action: PayloadAction<OperationOutcomeViewerOption>,
    ) => {
      state.viewerOption = action.payload;
    },
    setSelectedOperationId: (state, action: PayloadAction<string>) => {
      state.selectedOperationId = action.payload;
    },
    resetFilter: () => {
      return initialState;
    },
  },
});

export const operationOutcomesSelector = (state: RootState) =>
  state.operationOutcomes;

export const {
  setSearchTerm,
  setOrder,
  setOrderBy,
  setTake,
  setPage,
  setSource,
  setSeverity,
  setOpenModal,
  setViewerOption,
  setSelectedOperationId,
  setStartDate,
  setEndDate,
} = operationOutcomesSlice.actions;

export default operationOutcomesSlice.reducer;
