import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";

function ErrorPage() {
  const navigate = useNavigate();

  return (
    <div className="bg-surface-light flex-1 min-h-screen flex flex-col items-center justify-center">
      <div className="flex flex-col items-center justify-center gap-4">
        <div className="w-10 h-10">
          <ExclamationTriangleIcon className="text-2xl text-tyopography-primary" />
        </div>
        <p>La página que intenta acceder no existe.</p>
        <button onClick={() => navigate(-1)}>
          <span className="mx-1 text-black underline font-bold">
            Volver
          </span>
        </button>
      </div>
    </div>
  );
}

export default ErrorPage;
