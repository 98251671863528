import React from "react";
import { ArrowPathIcon } from "@heroicons/react/24/solid";

interface LoaderProps {
  size?: "small" | "medium" | "large";
  color?: "primary" | "secondary" | "white" | "error";
}

const sizeClasses = {
  small: "w-4 h-4",
  medium: "w-6 h-6",
  large: "w-8 h-8",
};

const colorClasses = {
  primary: "text-primary-main",
  secondary: "text-secondary-main",
  white: "text-white",
  error: "text-error-main",
};

const getSizeClass = (size: LoaderProps["size"]) =>
  sizeClasses[size ?? "medium"];

const getColorClass = (color: LoaderProps["color"]) =>
  colorClasses[color ?? "primary"];

const Loader = ({ size = "medium", color = "primary" }: LoaderProps) => {
  return (
    <ArrowPathIcon
      className={`animate-spin ${getSizeClass(size)} ${getColorClass(color)}`}
      aria-hidden="true"
    />
  );
};

export default Loader;
