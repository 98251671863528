interface SidebarItemProps {
  label: string;
  icon: React.ReactNode;
  currentRoute: boolean;
  expanded: boolean;
  onClick?: () => void;
}

function SidebarItem({
  label,
  icon,
  currentRoute,
  expanded,
  onClick,
}: Readonly<SidebarItemProps>) {
  return (
    <button
      data-testid={`sidebar-item-${label.toLowerCase()}`}
      type="button"
      className={`overflow-hidden transition-color duration-200 flex flex-row items-center justify-start gap-2 h-12 active:bg-slate-300 rounded-2xl ${
        currentRoute
          ? "bg-blue-900 text-white"
          : "bg-transparent hover:bg-slate-200"
      } ${expanded ? "w-full pl-6" : "w-16 pl-[1.375rem]"}`}
      onClick={onClick}
    >
      <div className="h-5 w-5 shrink-0">{icon}</div>
      <span
        className={`transition-opacity duration-200 text-start ${
          expanded ? "opacity-100" : "opacity-0"
        }`}
      >
        {label}
      </span>
    </button>
  );
}

export default SidebarItem;
