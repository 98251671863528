import { useAppSelector } from "hooks";
import { authSelector } from "modules/user/infrastructure/repository/auth/authSlice";
import { Navigate, Outlet } from "react-router-dom";

function AnonymousLayout() {
  const { accessToken } = useAppSelector(authSelector);

  return accessToken ? <Navigate to="/" /> : <Outlet />;
}

export default AnonymousLayout;
