import { ChevronDoubleLeftIcon } from "@heroicons/react/24/solid";

interface SidebarExpandButtonProps {
  isExpanded: boolean;
  toggleExpanded: () => void;
}

function SidebarExpandButton({
  isExpanded,
  toggleExpanded,
}: Readonly<SidebarExpandButtonProps>) {
  return (
    <div>
      <button
        type="button"
        onClick={() => toggleExpanded()}
        className="absolute top-3 -right-4 flex items-center justify-center w-8 h-8 rounded-full bg-white border border-slate-200 shadow-md"
      >
        <div
          className={`transition-transform duration-200 w-5 h-5 text-xs text-slate-500 ${
            isExpanded ? "rotate-0" : "rotate-180"
          }`}
        >
          <ChevronDoubleLeftIcon />
        </div>
      </button>
    </div>
  );
}

export default SidebarExpandButton;
