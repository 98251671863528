import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DictionaryFilters } from "modules/dictionaries/domain/types";
import { ResourceOperation, Source } from "modules/shared/application/types";
import { baseFiltersSlice } from "modules/shared/infrastructure/api/baseFiltersSlice";
import { RootState } from "modules/shared/infrastructure/redux/auditStore";

export interface DictionariesSlice extends DictionaryFilters {
  selectedDictionaryId?: number;
  operation: ResourceOperation;
}

const initialState: DictionariesSlice = {
  filters: {
    ...baseFiltersSlice.getInitialState().filters,
    source: "",
  },
  openModal: baseFiltersSlice.getInitialState().openModal,
  operation: "read",
  selectedDictionaryId: undefined,
};

export const dictionariesFilterSlice = createSlice({
  name: "dictionariesFilter",
  initialState,
  reducers: {
    ...baseFiltersSlice.caseReducers,
    setSource: (state, action: PayloadAction<Source>) => {
      state.filters.source = action.payload;
    },
    setResourceOperation: (state, action: PayloadAction<ResourceOperation>) => {
      state.operation = action.payload;
    },
    setSelectedDictionaryId: (state, action: PayloadAction<number>) => {
      state.selectedDictionaryId = action.payload;
    },
    resetFilter: () => {
      return initialState;
    },
  },
});

export const dictionariesSelector = (state: RootState) => state.dictionaries;

export const {
  setSearchTerm,
  setOrder,
  setOrderBy,
  setTake,
  setPage,
  setSource,
  setOpenModal,
  setResourceOperation,
  setSelectedDictionaryId,
  setStartDate,
  setEndDate,
} = dictionariesFilterSlice.actions;

export default dictionariesFilterSlice.reducer;
